import React from 'react'
import { Link } from 'gatsby'

import { PRODUCTS } from '../../constants'

import serviciosColesImg from '../../images/servicios-coles.png' 
import serviciosLechugasImg from '../../images/servicios-lechugas.png' 
import serviciosPrimaveraImg from '../../images/servicios-primavera.png' 
import serviciosOtrosImg from '../../images/servicios-otros.png'

import SideMenu from '../SideMenu'

const IMAGES = {
  'coles': serviciosColesImg,
  'lechugas': serviciosLechugasImg,
  'primavera': serviciosPrimaveraImg,
  'otros': serviciosOtrosImg,
}

const Item = ({ product }) => (
  <li>
    <Link to={`/${product}`}>
      <p>{product}</p>
      <div className="side-menu__image">
        <img src={IMAGES[product]} alt={product} />
      </div>
    </Link>
  </li>
)

const ServiciosMenu = () => {
  return (
    <SideMenu>
      <ul className="side-menu__items">
        {PRODUCTS.map(product => <Item key={`menu-item-${product}`} {...{ product }} />)}
      </ul>
    </SideMenu>
  )
}

export default ServiciosMenu