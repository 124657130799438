import React from 'react'
import { RichText } from 'prismic-reactjs'

import { getAnimation } from '../../utils'

import ServiciosMenuMobile from '../ServiciosMenuMobile'
import DoctorPlantcanalMobile from '../DoctorPlantcanalMobile'

import './styles.css'

const Features = ({ features = [] }) => features.map((feature, index) => (
  <li key={`feat-${index}`} {...getAnimation(250 * (index + 1))}>
    <RichText render={feature?.description?.raw} />
  </li>
))

const ServiciosContent = ({ data }) => (
  <div className="servicios content">
    <section className="servicios__intro" {...getAnimation(0)}>
      <RichText render={data?.introduction?.raw} />
      <ul>
        <Features features={data?.types} />
      </ul>
    </section>
    <section className="servicios__features" {...getAnimation(0)}>
      <RichText render={data?.delivery?.raw} />
    </section>
    <ServiciosMenuMobile />
    <section className="servicios__products" {...getAnimation(0)}>
    <RichText render={data?.info?.raw} />
    </section>
    <DoctorPlantcanalMobile />
  </div>
)

export default ServiciosContent