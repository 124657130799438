import React from 'react'
import { Link } from 'gatsby'

import { PRODUCTS } from '../../constants'

import './styles.css'

const Item = ({ product }) => (
  <li>
    <Link to={`/${product}`}>
      <p>{product}</p>
      <div className="servicios-menu-mobile__image">
        <img src={`images/servicios-${product}.png`} alt={product} />
      </div>
    </Link>
  </li>
)

const ServiciosMenuMobile = () => (
  <div className="servicios-menu-mobile">
    <ul>
      {PRODUCTS.map(product => <Item key={`menu-item-mobile-${product}`} {...{ product }} />)}
    </ul>
  </div>
)

export default ServiciosMenuMobile