import React, { useEffect, useState } from 'react'
import { Link } from "gatsby";

import { debounce } from '../../utils'
import doctorImg from '../../images/doctor-plantcanal.png'

import './styles.css'

const Bubble = ({ showContact }) => {
  const getClassName = (showContact) => {
    if (showContact) return "doctor-plantcanal__bubble doctor-plantcanal__bubble--hide"
    return "doctor-plantcanal__bubble"
  }

  return (
    <div className={getClassName(showContact)}>
      <span>¿En que puedo<br />ayudarte?</span>
    </div>
  )
}

const SmallBubble = () => (
  <div className="doctor-plantcanal__bubble doctor-plantcanal__bubble--small">
    <span>Hola</span>
  </div>
)

const ContactBubble = ({ showContact }) => {
  const getClassName = (showContact) => {
    if (showContact) return "doctor-plantcanal__bubble doctor-plantcanal__bubble--contact doctor-plantcanal__bubble--show"
    return "doctor-plantcanal__bubble doctor-plantcanal__bubble--contact"
  }

  return (
    <Link className={getClassName(showContact)} to="/contacto">
      <span>contáctanos</span>
    </Link>
  )
}

const DoctorPlantcanal = ({ noScroll }) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const [showContact, setShowContact] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.src = doctorImg
    img.onload = () => setLoaded(true)

    let isMounted = true
    let showContactButton = null

    const handleSetScroll = () => {
      if (hasScrolled ||document?.scrollTop === 0) return 
      
      setHasScrolled(true)

      showContactButton = setTimeout(() => {
        if (!isMounted) return
        setShowContact(true)
      }, 2000);
    }
    
    if (document === 'undefined') return 
    
    if (noScroll) {
      handleSetScroll()
      return
    }
    
    const handleScroll = debounce(handleSetScroll, 100)
    document.addEventListener('scroll', handleScroll, false)

    return () => {
      isMounted = false
      clearTimeout(showContactButton)

      if (noScroll) return
      document.removeEventListener('scroll', handleScroll, false)
    }
  }, [])

  const getClassName = () => {
    if (hasScrolled && loaded) return "doctor-plantcanal doctor-plantcanal--visible"
    return "doctor-plantcanal"
  } 

  return (
    <div className={getClassName()}>
      <Bubble {...{ showContact }} />
      <SmallBubble />
      <ContactBubble {...{ showContact }} />
      <div className="doctor-plantcanal__doctor">
        <div className="doctor-plantcanal__image">
          <img src={doctorImg} alt="Dr. Plantcanal" />
        </div>
        <p className="doctor-plantcanal__doctor__title">Dr. Plantcanal</p>
      </div>
    </div>
  )
}

export default DoctorPlantcanal