import React from 'react'

import DoctorPlantcanal from '../DoctorPlantcanal/DoctorPlantcanal'

import './styles.css'

const DoctorPlantcanalMobile = () => {
  return (
    <div className="doctor-plantcanal-mobile">
      <DoctorPlantcanal />
    </div>
  )
}

export default DoctorPlantcanalMobile