import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ServiciosContent from "../components/ServiciosContent"
import ServiciosMenu from "../components/ServiciosMenu"
import DoctorPlantcanal from "../components/DoctorPlantcanal"

import '../styles/index.css'

const Servicios = ({ data, location }) => (
  <Layout pathname={location?.pathname}>
    <SEO title="Servicios" />
    <div className="sidebar sidebar--left sidebar--hide-mobile">
      <ServiciosMenu />
    </div>
    <ServiciosContent data={data?.allPrismicServicios?.edges?.[0]?.node?.data} />
    <div className="sidebar sidebar--right sidebar--only-desktop">
      <DoctorPlantcanal key="servicios-doctor" noScroll />
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allPrismicServicios {
      edges {
        node {
          data {
            delivery {
              html
              text
              raw
            }
            info {
              html
              text
              raw
            }
            introduction {
              html
              text
              raw
            }
            title {
              html
              text
              raw
            }
            types {
              description {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  }
`

export default Servicios
