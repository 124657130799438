import React from 'react'

import './styles.css'

const SideMenu = ({ children }) => {
  return (
    <nav className="side-menu">
      {children}
    </nav>
  )
}

export default SideMenu